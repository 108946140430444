import useAuthenticatedFetch from '../hooks/useAuthenticatedFetch';

const API_URL = process.env.REACT_APP_API_URL;

const apiService = {
  useApi() {
    const authenticatedFetch = useAuthenticatedFetch();
    let ws = null;
    let messageHandlers = new Set();
    let reconnectAttempts = 0;
    let isConnecting = false;
    const MAX_RECONNECT_ATTEMPTS = 5;
    
    const connectWebSocket = (clientId) => {
      if (isConnecting) return ws;
      if (ws?.readyState === WebSocket.OPEN) return ws;
      
      isConnecting = true;

      const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const wsHost = API_URL.replace(/^https?:\/\//, '');
      const wsUrl = `${wsProtocol}//${wsHost}/api/rag/ws/${clientId}`;
      
      console.log('Attempting WebSocket connection:', wsUrl);
      
      if (ws) {
        ws.close();
        ws = null;
      }

      const socket = new WebSocket(wsUrl);

      socket.onmessage = (event) => {
        console.log('WebSocket received message:', event.data);
        try {
          const message = JSON.parse(event.data);
          console.log('Parsed WebSocket message:', message);
          messageHandlers.forEach(handler => {
            console.log('Calling message handler');
            handler(message);
          });
        } catch (error) {
          console.error('Error processing WebSocket message:', error);
        }
      };

      socket.onopen = () => {
        console.log('WebSocket connected successfully');
        isConnecting = false;
        reconnectAttempts = 0;
        messageHandlers.forEach(handler => handler({ 
          type: 'connection_status', 
          status: 'connected' 
        }));
      };

      socket.onclose = (event) => {
        console.log('WebSocket closed:', event);
        isConnecting = false;
        
        messageHandlers.forEach(handler => handler({ 
          type: 'connection_status', 
          status: 'disconnected' 
        }));
        
        if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
          reconnectAttempts++;
          setTimeout(() => {
            if (!isConnecting && ws === socket) {
              ws = connectWebSocket(clientId);
            }
          }, Math.min(1000 * Math.pow(2, reconnectAttempts), 5000));
        } else {
          messageHandlers.forEach(handler => handler({ 
            type: 'connection_status', 
            status: 'failed' 
          }));
        }
      };

      ws = socket;
      return socket;
    };

    const ensureWebSocketConnection = async (clientId) => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        return ws;
      }

      return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          reject(new Error('WebSocket connection timeout'));
        }, 5000);

        if (ws) {
          ws.close();
        }

        ws = connectWebSocket(clientId);

        ws.onopen = () => {
          clearTimeout(timeout);
          resolve(ws);
        };

        ws.onerror = (error) => {
          clearTimeout(timeout);
          reject(error);
        };
      });
    };

    const cleanup = () => {
      if (ws) {
        ws.close();
        ws = null;
      }
      messageHandlers.clear();
      reconnectAttempts = 0;
    };

    const sendChatMessage = async (messageData) => {
      if (!messageData.query) {
        throw new Error('Chat message cannot be empty');
      }

      try {
        const clientId = messageData.conversation_id;
        const socket = await ensureWebSocketConnection(clientId);

        return new Promise((resolve, reject) => {
          const messageHandler = (message) => {
            console.log('Received chat response:', message);
            
            switch (message.type) {
              case 'chat_response':
                messageHandlers.delete(messageHandler);
                resolve({
                  message: message.data?.message,
                  search_results: message.data?.search_results
                });
                break;
              
              case 'results':
                messageHandlers.delete(messageHandler);
                resolve({
                  message: message.data?.message,
                  search_results: message.search_results
                });
                break;
              
              case 'error':
                messageHandlers.delete(messageHandler);
                reject(new Error(message.data?.error || 'Chat failed'));
                break;
              
              case 'processing':
                console.log('Processing message:', message.data?.message);
                break;
                
              default:
                console.log('Unhandled message type:', message.type);
            }
          };

          messageHandlers.add(messageHandler);
          
          const payload = {
            type: 'chat_message',
            query: messageData.query,
            conversation_id: clientId,
            searchContext: messageData.searchContext
          };

          console.log('Sending chat payload:', payload);
          socket.send(JSON.stringify(payload));
        });
      } catch (error) {
        console.error('Error in sendChatMessage:', error);
        throw error;
      }
    };

    return {
      searchProperties: async (query, page = 1, pageSize = 10, cacheKey = null) => {
        console.log('API searchProperties called with:', { query, page, pageSize, cacheKey });
        try {
          const clientId = 'search-' + Date.now();
          console.log('Created clientId:', clientId);
          
          const socket = await ensureWebSocketConnection(clientId);
          console.log('WebSocket connected');

          return new Promise((resolve, reject) => {
            console.log('Setting up search handler');
            const searchHandler = (message) => {
              console.log('Search handler received message:', message);
              console.log('Raw search response:', message);

              if (message.type === 'results') {
                messageHandlers.delete(searchHandler);
                
                const searchResults = message.search_results;
                console.log('Extracted search results:', searchResults);
                
                const results = {
                  features: searchResults?.features || [],
                  total: searchResults?.total || message.pagination?.total || 0,
                  poi: searchResults?.poi || [],
                  context: message.data?.context || {},
                  pagination: {
                    page: searchResults?.page || message.pagination?.page || 1,
                    pages: searchResults?.pages || message.pagination?.pages || 1,
                    total: searchResults?.total || message.pagination?.total || 0,
                    cacheKey: searchResults?.cache_key || message.pagination?.cache_key
                  }
                };

                console.log('Formatted results to return:', results);
                resolve(results);
              } else if (message.type === 'error') {
                messageHandlers.delete(searchHandler);
                reject(new Error(message.data?.error || 'Search failed'));
              }
            };

            messageHandlers.add(searchHandler);
            
            const messageData = {
              type: 'search',
              query,
              conversation_id: clientId,
              limit: pageSize,
              offset: (page - 1) * pageSize
            };

            console.log('Sending search message:', messageData);
            socket.send(JSON.stringify(messageData));
          });
        } catch (error) {
          console.error('Error in searchProperties:', error);
          throw error;
        }
      },

      getNextPage: async (cacheKey, page, pageSize = 10) => {
        return apiService.useApi().searchProperties(null, page, pageSize, cacheKey);
      },

      getPropertyDetails: async (propertyId) => {
        try {
          const response = await authenticatedFetch(`${API_URL}/properties/${propertyId}`);
          return response;
        } catch (error) {
          console.error('Error fetching property details:', error);
          throw error;
        }
      },

      getNearbyProperties: async (lat, lon, distance) => {
        try {
          const response = await authenticatedFetch(`${API_URL}/properties/nearby?lat=${lat}&lon=${lon}&distance=${distance}`);
          return response;
        } catch (error) {
          console.error('Error fetching nearby properties:', error);
          throw error;
        }
      },

      subscribeToMessages: (handler) => {
        messageHandlers.add(handler);
        return () => messageHandlers.delete(handler);
      },

      sendChatMessage,

      getChatHistory: async (conversationId) => {
        try {
          const protocol = window.location.protocol;
          const baseUrl = API_URL || `${protocol}//${window.location.hostname}:8000`;
          
          const response = await authenticatedFetch(`${baseUrl}/api/rag/chat/history/${conversationId}`);
          return response;
        } catch (error) {
          console.error('Error fetching chat history:', error);
          throw error;
        }
      },

      disconnectWebSocket: () => {
        cleanup();
      }
    };
  }
};

export default apiService;