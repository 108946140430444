import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import Map from './components/Map';
import PropertyList from './components/PropertyList';
import PropertyDetails from './components/PropertyDetails';
import Login from './components/Login';
import LoadingAnimation from './components/LoadingAnimation';
import NoResultsMessage from './components/NoResultsMessage';
import apiService from './services/api';
import ChatWindow from './components/ChatWindow/ChatWindow';

const AppContent = () => {
  const { isLoaded, isSignedIn, user } = useUser();
  const api = apiService.useApi();
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedPropertyDetails, setSelectedPropertyDetails] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProperties, setTotalProperties] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [poiInfo, setPoiInfo] = useState(null);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [loadingInterval, setLoadingInterval] = useState(null);
  const pageSize = 10;
  const searchTimeoutRef = useRef(null);
  const isSearchingRef = useRef(false);
  const debounceTimerRef = useRef(null);
  const [conversationId] = useState(`chat-${Date.now()}`);
  const [isChatMinimized, setIsChatMinimized] = useState(false);

  const fetchProperties = useCallback(async (query, page) => {
    console.log('fetchProperties called with:', query, page);
    if (isSearchingRef.current) {
      console.log('Search already in progress, returning');
      return;
    }
    
    try {
      console.log('Starting new search');
      isSearchingRef.current = true;
      setIsSearching(true);
      setLoadingProgress(0);
      setNoResults(false);
      setHasSearched(false);
      
      const animateLoading = async () => {
        const loadingMessages = [
          'Analyzing your query...',
          'Searching our vast database...',
          'Crunching the numbers...',
          'Filtering results...',
          'Preparing property details...',
          'Almost there...'
        ];
        const totalSteps = loadingMessages.length;
        const progressPerStep = 95 / totalSteps;

        for (let i = 0; i < loadingMessages.length; i++) {
          setLoadingMessage(loadingMessages[i]);
          const endProgress = (i + 1) * progressPerStep;

          await new Promise(resolve => {
            const interval = setInterval(() => {
              setLoadingProgress(prev => {
                const newProgress = prev + Math.random() * 0.3;
                if (newProgress >= endProgress) {
                  clearInterval(interval);
                  resolve();
                }
                return Math.min(newProgress, endProgress);
              });
            }, 100);
            setLoadingInterval(interval);
          });

          if (!isSearchingRef.current) break;
          await new Promise(resolve => setTimeout(resolve, Math.random() * 1000 + 500));
        }
      };

      // Start animation in parallel with search
      animateLoading();
      console.log('Sending search request for query:', query); // Debug log
      const searchPromise = api.searchProperties(query, page, pageSize);

      // Wait for search results
      const results = await searchPromise;
      console.log('Received results in fetchProperties:', results); // Debug log
      
      if (results?.features) {
        console.log('Setting properties with features:', results.features); // Debug log
        setProperties(results.features);
        setTotalProperties(results.total || 0);
        setCurrentPage(page);
        setSelectedProperty(null);
        setSelectedPropertyDetails(null);
        setHasSearched(true);  // Make sure this is being set
        setNoResults(results.features.length === 0);
        setPoiInfo(results.poi);
      } else {
        console.error('Invalid results format:', results);
        throw new Error("Unexpected response from server");
      }

    } catch (error) {
      console.error('Error fetching properties:', error);
      setProperties([]);
      setTotalProperties(0);
      setLoadingMessage('Error occurred. Please try again.');
      setNoResults(true);
      setHasSearched(true);
    } finally {
      isSearchingRef.current = false;
      setIsSearching(false);
      setLoadingProgress(100);
      if (loadingInterval) {
        clearInterval(loadingInterval);
        setLoadingInterval(null);
      }
    }
  }, [api, loadingInterval, pageSize]);

  const handleSearch = useCallback((searchData) => {
    console.log('handleSearch called with:', searchData);
    clearTimeout(searchTimeoutRef.current);
    searchTimeoutRef.current = setTimeout(() => {
      console.log('Debounce timer fired, calling fetchProperties');
      setSearchQuery(searchData.query);
      setCurrentPage(1);
      fetchProperties(searchData.query, 1);
    }, 300); // Debounce time
  }, [fetchProperties]);

  const fetchPropertyDetails = useCallback(async (propertyId) => {
    try {
      const details = await api.getPropertyDetails(propertyId);
      console.log('setting selected property details:')
      console.log(details);
      setSelectedPropertyDetails(details.properties);
    } catch (error) {
      console.error('Error fetching property details:', error);
    }
  }, [api]);

  const handleSelectProperty = useCallback((property) => {
    console.log("Selected property:", property);
    setSelectedProperty(property);
  }, []);

  const handleViewDetails = useCallback((property) => {
    if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
        console.log("Viewing details for property:", property);
        if (property && property.attomid) {
            console.log("Fetching details for property ID:", property.attomid);
            setIsLoadingDetails(true);
            fetchPropertyDetails(property.attomid)
                .finally(() => setIsLoadingDetails(false));
        } else {
            console.error("Invalid property object:", property);
        }
    }, 300);
  }, [fetchPropertyDetails]);

  const handlePageChange = useCallback((newPage) => {
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
      fetchProperties(searchQuery, newPage);
    }
  }, [fetchProperties, searchQuery, currentPage]);

  const handleClosePropertyDetails = useCallback(() => {
    setSelectedPropertyDetails(null);
  }, []);

  const handleChatSearchResults = useCallback((results) => {
    console.log('Handling chat search results:', results); // Debug log

    if (results?.features) {
      setIsSearching(false); // Stop any loading states
      setLoadingProgress(100);
      if (loadingInterval) {
        clearInterval(loadingInterval);
        setLoadingInterval(null);
      }

      // Update all necessary states
      setProperties(results.features);
      setTotalProperties(results.total || 0);
      setCurrentPage(1); // Reset to first page
      setSelectedProperty(null); // Clear selected property
      setSelectedPropertyDetails(null); // Clear property details
      setHasSearched(true); // Important for visibility
      setNoResults(results.features.length === 0);
      setPoiInfo(results.poi);
      
      // Update search query if provided
      if (results.context?.query) {
        setSearchQuery(results.context.query);
      }
    }
  }, [loadingInterval]);

  const handleToggleChat = useCallback(() => {
    setIsChatMinimized(prev => !prev);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(searchTimeoutRef.current);
      clearTimeout(debounceTimerRef.current);
      if (loadingInterval) {
        clearInterval(loadingInterval);
      }
    };
  }, [loadingInterval]);

  if (!isLoaded) {
    return <LoadingAnimation message="Initializing..." />;
  }

  return (
    <div className="app-container">
      <Header user={user} />
      <Routes>
        <Route 
          path="/login" 
          element={!isSignedIn ? <Login /> : <Navigate to="/" replace />} 
        />
        <Route
          path="/"
          element={
            isSignedIn ? (
              <main className="main-content" style={{
                position: 'relative',
                minHeight: '100vh',
              }}>
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundImage: `url(/images/${!hasSearched ? 'bg.png' : (noResults ? 'sat-map-bg.png' : '')})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  zIndex: 1,
                  display: (!hasSearched || noResults) ? 'block' : 'none',
                }}>
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  }} />
                </div>
                {!hasSearched && (
                  <div className="home-screen-text" style={{
                    position: 'absolute',
                    top: '30%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    color: 'white',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
                    zIndex: 2,
                  }}>
                    <h1 className="search-headline" style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>
                      Skip the filters, just type and simplify your commercial property search.
                    </h1>
                  </div>
                )}
                <div className={`search-container ${hasSearched ? (properties.length > 0 ? '' : 'search-bar-top') : 'search-bar-centered'}`} style={{ zIndex: 2, position: 'relative' }}>
                  <SearchBar 
                    onSearch={handleSearch} 
                    disabled={isSearching}
                    hasResults={hasSearched && properties.length > 0}
                  />
                </div>
                {isSearching && <LoadingAnimation message={loadingMessage} progress={loadingProgress} />}
                {hasSearched && !noResults && (
                  <div className={`content-wrapper ${properties.length > 0 ? 'visible' : ''}`}>
                    <div className="map-container">
                      <Map 
                        properties={properties} 
                        selectedProperty={selectedProperty} 
                        onPropertySelect={handleSelectProperty}
                        onViewDetails={handleViewDetails}
                        poi={poiInfo}
                        isLoadingDetails={isLoadingDetails}
                      />
                    </div>
                    {selectedPropertyDetails ? (
                      <PropertyDetails 
                        property={selectedPropertyDetails} 
                        onClose={handleClosePropertyDetails} 
                      />
                    ) : (
                      <PropertyList 
                        properties={properties} 
                        onSelectProperty={handleSelectProperty}
                        selectedProperty={selectedProperty}
                        currentPage={currentPage}
                        totalProperties={totalProperties}
                        pageSize={pageSize}
                        onPageChange={handlePageChange}
                        searchQuery={searchQuery}
                      />
                    )}
                  </div>
                )}
                {noResults && (
                  <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                  }}>
                    <NoResultsMessage />
                  </div>
                )}
              </main>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
      
      {isSignedIn && hasSearched && (
        <ChatWindow
          api={api}
          conversationId={conversationId}
          onSearchResults={handleChatSearchResults}
          searchContext={properties}
          isMinimized={isChatMinimized}
          onToggleMinimize={handleToggleChat}
        />
      )}
    </div>
  );
};

export default AppContent;