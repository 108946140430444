import React from 'react';
import PropertyItem from './PropertyItem';
import Pagination from './Pagination';

const PropertyList = ({ 
  properties, 
  onSelectProperty, 
  selectedProperty,
  currentPage,
  totalProperties,
  pageSize,
  onPageChange
}) => {
  return (
    <div className="property-list">
      <h2>Search Results ({totalProperties})</h2>
      {properties.length === 0 ? (
        <p>No properties found. Try adjusting your search criteria.</p>
      ) : (
        <>
          <div className="property-grid">
            {properties.map((property) => (
              <PropertyItem
                key={property.attomid}
                property={property}
                onSelect={() => onSelectProperty(property)}
                isSelected={selectedProperty && 
                  (selectedProperty.attomid === property.attomid)}
              />
            ))}
          </div>
          <Pagination 
            currentPage={currentPage}
            totalPages={Math.ceil(totalProperties / pageSize)}
            onPageChange={onPageChange}
          />
        </>
      )}
    </div>
  );
};

export default PropertyList;