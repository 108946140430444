import React, { useState, useRef, useEffect } from 'react';
import './ChatWindow.css';

const ChatWindow = ({ 
  api,  // This should be the result of apiService.useApi()
  conversationId,
  isMinimized = false,
  onToggleMinimize = () => {},
  onSearchResults,
  searchContext  // Add this prop
}) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('disconnected');
  const [chatEnabled, setChatEnabled] = useState(false);
  
  // Add ref for message scrolling
  const messagesEndRef = useRef(null);
  const hasInitialized = useRef(false);
  const connectionAttempted = useRef(false);
  const inputRef = useRef(null); // Add this ref for the input

  // Combine the initialization effects
  useEffect(() => {
    if (searchContext?.length > 0 && !hasInitialized.current && !connectionAttempted.current) {
      hasInitialized.current = true;
      connectionAttempted.current = true;
      setChatEnabled(true);
      
      // Add initial welcome message
      setMessages([{
        role: 'assistant',
        content: `I found some properties matching your search...`
      }]);

      // Single connection attempt
      api.sendChatMessage('', conversationId, searchContext)
        .catch(error => {
          console.error('Initial connection error:', error);
          setConnectionStatus('failed');
        });
    }
  }, [api, conversationId, searchContext]);

  // WebSocket connection effect
  useEffect(() => {
    if (!searchContext || !searchContext.length || !conversationId || !chatEnabled) {
      return; // Don't connect if we don't have required data
    }

    let isSubscribed = true;
    
    const handleMessage = (message) => {
      if (!isSubscribed) return;
      
      if (message.type === 'connection_status') {
        setConnectionStatus(message.status);
      }
    };

    const unsubscribe = api.subscribeToMessages(handleMessage);
    
    return () => {
      isSubscribed = false;
      unsubscribe();
    };
  }, [api, conversationId, searchContext, chatEnabled, connectionStatus]);

  // Enable chat when search results are received
  useEffect(() => {
    const handleSearchResults = (results) => {
      if (results && !chatEnabled) {
        setChatEnabled(true);
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: 'I can help you learn more about these properties. What would you like to know?'
        }]);
      }
    };

    if (onSearchResults) {
      handleSearchResults(onSearchResults);
    }
  }, [onSearchResults, chatEnabled, setMessages]);

  useEffect(() => {
    const handleMessage = (message) => {
      console.log('ChatWindow received message:', message); // Add debugging

      switch (message.type) {
        case 'connection_status':
          setConnectionStatus(message.status);
          break;
          
        case 'processing':
          setMessages(prev => {
            const lastMessage = prev[prev.length - 1];
            if (lastMessage?.type === 'processing' && 
                lastMessage.content === message.data.message) {
              return prev;
            }
            return [...prev, {
              role: 'assistant',
              type: 'processing',
              content: message.data.message
            }];
          });
          break;
          
        case 'results':
          setMessages(prev => {
            const filteredMessages = prev.filter(msg => msg.type !== 'processing');
            return [...filteredMessages, {
              role: 'assistant',
              content: message.data.message || 'I found some matching properties.'
            }];
          });
          
          // Make sure we're passing search results to parent
          if (message.search_results && onSearchResults) {
            console.log('Passing search results to parent:', message.search_results);
            onSearchResults(message.search_results);
          }
          break;
          
        case 'error':
          setMessages(prev => {
            const filteredMessages = prev.filter(msg => msg.type !== 'processing');
            return [...filteredMessages, {
              role: 'assistant',
              type: 'error',
              content: message.data.error || 'An error occurred'
            }];
          });
          break;
          
        default:
          console.log('Unhandled message type:', message.type);
          break;
      }
    };

    const unsubscribe = api.subscribeToMessages(handleMessage);
    return () => unsubscribe();
  }, [api, onSearchResults]);

  // Add scroll to bottom effect
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim() || isLoading) return;

    const userMessage = {
      type: 'user',
      content: inputValue.trim(),
      timestamp: new Date().toISOString()
    };

    try {
      setIsLoading(true);
      setMessages(prev => [...prev, userMessage]);
      setInputValue('');

      const response = await api.sendChatMessage({
        query: userMessage.content,
        conversation_id: conversationId,
        searchContext: searchContext
      });

      // Always add the assistant's response message if it exists
      if (response.message) {
        const assistantMessage = {
          type: 'assistant',
          content: response.message,
          timestamp: new Date().toISOString()
        };
        setMessages(prev => [...prev, assistantMessage]);
      }

      // Handle search results if they exist
      if (response.search_results) {
        onSearchResults(response.search_results);
      }

    } catch (error) {
      console.error('Error sending chat message:', error);
      setMessages(prev => [...prev, {
        type: 'error',
        content: error.message || 'Sorry, there was an error processing your message. Please try again.',
        timestamp: new Date().toISOString()
      }]);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const formatTimestamp = (timestamp) => {
    try {
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) return '';
      
      return date.toLocaleTimeString([], { 
        hour: 'numeric', 
        minute: '2-digit',
        hour12: true 
      });
    } catch (e) {
      return '';
    }
  };

  /*
  const renderMessageContent = (message) => {
    if (message.role === 'assistant' && message.content.includes('I found some properties')) {
      return (
        <div className="welcome">
          {message.content.split('\n').map((line, i) => {
            if (line.startsWith('•')) {
              return <li key={i}>{line.substring(1).trim()}</li>;
            }
            return <p key={i}>{line}</p>;
          })}
        </div>
      );
    }
    
    switch (message.type) {
      case 'processing':
        return (
          <div className="processing-message">
            <div className="typing-indicator">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div>{message.content}</div>
          </div>
        );
      
      case 'info':
        return (
          <div className="info-message">
            <i className="fas fa-info-circle"></i>
            <span>{message.content}</span>
          </div>
        );
      
      case 'clarification':
        return (
          <div className="clarification-message">
            <div>{message.content}</div>
            {message.options && (
              <div className="clarification-options">
                {message.options.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => handleClarificationOption(option)}
                    className="clarification-option"
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
          </div>
        );
      
      case 'error':
        return (
          <div className="error-message">
            <i className="fas fa-exclamation-circle"></i>
            <span>{message.content}</span>
          </div>
        );
      
      default:
        return <div>{message.content}</div>;
    }
  };
  */

  /*
  const handleClarificationOption = async (option) => {
    setIsLoading(true);
    try {
      // Add the selected option as a user message
      setMessages(prev => [...prev, {
        role: 'user',
        content: option
      }]);

      // Send the clarification response
      const response = await api.sendChatMessage(
        option,
        conversationId,
        { type: 'clarification', selectedOption: option },
        activeRefinement
      );

      // Handle the response
      if (response.type === 'results') {
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: response.data.message,
          context: response.data.context
        }]);
        
        if (response.search_results) {
          onSearchResults(response.search_results);
        }
      } else if (response.type === 'clarification') {
        setMessages(prev => [...prev, {
          role: 'assistant',
          type: 'clarification',
          content: response.data.message,
          options: response.data.options,
          context: response.data.context
        }]);
      }
    } catch (error) {
      console.error('Error handling clarification:', error);
      setMessages(prev => [...prev, {
        role: 'assistant',
        type: 'error',
        content: 'I apologize, but I encountered an error processing your selection. Please try again.'
      }]);
    } finally {
      setIsLoading(false);
      setActiveRefinement(null);
    }
  };
  */

  /*
  // Add handler for refinement options
  const handleRefinementOption = async (option) => {
    if (!chatEnabled || isLoading) return;
    
    setInputValue(option);
    const fakeEvent = { preventDefault: () => {} };
    await handleSubmit(fakeEvent);
  };
  */

  if (!searchContext || searchContext.length === 0) {
    return null;
  }

  if (isMinimized) {
    return (
      <div 
        className="chat-window-minimized" 
        onClick={onToggleMinimize}
        title="Open chat"
      >
        <i className="fas fa-comments" style={{ fontSize: '24px' }}></i>
      </div>
    );
  }

  return (
    <div className={`chat-window ${isMinimized ? 'minimized' : ''}`}>
      <div className="chat-header">
        <div className="chat-title">Chat Assistant</div>
        <div className="minimize-button" onClick={onToggleMinimize} />
      </div>
      
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.type || 'assistant'}`}>
            <div className="message-content">{message.content}</div>
            {message.timestamp && (
              <div className="message-timestamp">
                {formatTimestamp(message.timestamp)}
              </div>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="chat-input-container">
        <form className="chat-input-form" onSubmit={handleSubmit}>
          <input
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type your message..."
            disabled={isLoading}
          />
          <button 
            type="submit" 
            className="send-button" 
            disabled={isLoading || !inputValue.trim()}
          >
            <svg viewBox="0 0 24 24">
              <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatWindow;